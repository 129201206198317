<template>
  <v-card outlined flat>
    <v-card-title v-if="isInCreationMode">
      {{ $t('pages.leaderboard_definitions.creation_title') }}
    </v-card-title>
    <v-card-text>
      <v-form ref="leaderboardDefinition" v-model="validLeaderboardDefinition">
        <v-text-field v-bind:label="$t('common.labels.identifier')" name="identifier" type="text" v-model="model.identifier"
                      @keypress.enter.prevent="submit"
                      :rules="[(v) => v !== '' || $t('errors.empty', {the: $t('common.words.The_male'), name: $t('common.labels.mname')})]"/>
      </v-form>

    <v-row class="mb-5 px-5 text-h6">
      {{ $t('pages.leaderboard_definitions.rewards_choice') }} :
    </v-row>
    <v-row class="mb-5 px-5">
      <RewardSelection class="px-5" @selected="updateSelection"></RewardSelection>
    </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn v-if="isInCreationMode" depressed color="primary" @click="submit"
             :loading="createMixin.state === createMixin.STATES.CREATING"
             :disabled="!validLeaderboardDefinition">
        <span>{{ $t('common.action.create') }}</span>
      </v-btn>
      <v-btn v-else depressed color="primary" @click="submit"
             :loading="updateMixin.state === updateMixin.STATES.CREATING"
             :disabled="!validLeaderboardDefinition">
        <span>{{ $t('common.action.modify') }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import LeaderboardDefinitionService from '@/services/gamificator/leaderboarddefinition.service';
import CreateMixin from '@/mixins/create.mixin';
import UpdateMixin from '@/mixins/update.mixin';
import RewardSelection from '@/components/rewardDefinition/RewardSelection.vue';

export default {
  name: 'leaderboardDefinition-creation-leaderboardDefinition',
  components: { RewardSelection },
  mixins: [CreateMixin, UpdateMixin],
  props: ['value'],
  data: () => ({
    model: {},
    validLeaderboardDefinition: false,
  }),
  watch: {
    value() {
      this.model = { ...this.value };
    },
  },
  methods: {
    updateSelection(selection) {
      this.$set(this.model, 'rewardDefinitionIds', selection);
    },
    submit() {
      this.$refs.leaderboardDefinition.validate();
      if (!this.validLeaderboardDefinition || this.model.rewardDefinitionIds === []) return;
      if (this.isInCreationMode) {
        this.create();
      } else {
        this.update();
      }
    },
    createLeaderboardDefinition() {
      return LeaderboardDefinitionService.createLeaderboardDefinition(this.model);
    },
    updateLeaderboardDefinition() {
      const { id, ...rest } = this.model;
      return LeaderboardDefinitionService.updateLeaderboardDefinition(id, rest);
    },
  },
  computed: {
    isInCreationMode() {
      return this.model.id === null || this.model.id === undefined;
    },
  },
  created() {
    this.createMixin.config = {
      create: 'createLeaderboardDefinition',
    };
    this.updateMixin.config = {
      update: 'updateLeaderboardDefinition',
    };
    this.model = { ...this.value };
  },

};
</script>
