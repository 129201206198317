<template>
  <v-col>
    <leaderboard-definition-save-form @created="addToList"/>
    <v-card class="mt-4" flat tile :loading="isLoading">
      <v-card-title>
        <div>{{ $t('pages.leaderboard_definitions.list_title') }}</div>
      </v-card-title>

      <v-card-text v-if="isError">
        <div>{{ $t('errors.basic') }}</div>
      </v-card-text>

      <v-card-text v-if="leaderboardDefinitions.length === 0">
        {{ $t('errors.unavailable', {name: $t('common.words.mranking')}) }}
      </v-card-text>

      <v-card-text v-else-if="isIdle || isLoading">
        <v-simple-table>
          <thead>
          <tr>
            <th class="text-center">{{ $t('common.labels.id') }}</th>
            <th class="text-left">{{ $t('common.labels.identifier') }}</th>
            <th class="text-left">{{ $t('common.words.rewards') }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="leaderboardDefinition in leaderboardDefinitions" :key="leaderboardDefinition.id">
            <td class="text-center">{{ leaderboardDefinition.id }}</td>
            <td class="text-left">{{ leaderboardDefinition.identifier }}</td>
            <td class="text-center pa-3">
              <div v-for="rewardDefinition in leaderboardDefinition.rewardDefinitions" :key="rewardDefinition.id">
                <reward-definition-inline class="" :reward-definition="rewardDefinition"/>
              </div>
            </td>
            <td class="text-right">
              <confirm-button width="400px" @click="remove(leaderboardDefinition.id)">
                <template v-slot:button="{ on, attrs }">
                  <v-btn
                    icon color="error"
                    v-on="on" v-bind="attrs"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>

                <template v-slot:title>
                  {{ $t('pages.leaderboard_definitions.delete_validation_1') }}<br/>
                  {{ $t('pages.leaderboard_definitions.delete_validation_2') }}
                </template>

                <template v-slot:message>
                  {{ $t('pages.leaderboard_definitions.delete_validation_message') }}
                </template>
              </confirm-button>
            </td>
          </tr>
          </tbody>
        </v-simple-table>

        <paginator
          v-if="fetchMixin.pagination"
          :pagination="fetchMixin.pagination"
          @update="updatePagination"
        />
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import LeaderboardDefinitionService from '@/services/gamificator/leaderboarddefinition.service';
import Paginator from '@/components/util/paginator.vue';

import FetchMixin from '@/mixins/fech.mixin';
import DeleteMixin from '@/mixins/delete.mixin';
import ConfirmButton from '@/components/util/ConfirmButton.vue';
import LeaderboardDefinitionSaveForm from '@/components/leaderboardDefinition/leaderboardDefinitionSaveForm.vue';
import RewardDefinitionInline from '@/components/rewardDefinition/RewardDefinitionInline.vue';

export default {
  name: 'leaderboardDefinitionsView',
  mixins: [FetchMixin, DeleteMixin],
  components: {
    RewardDefinitionInline,
    ConfirmButton,
    Paginator,
    LeaderboardDefinitionSaveForm,
  },
  data: () => ({
    leaderboardDefinitions: [],
  }),
  methods: {
    fetchLeaderboardDefinitions() {
      const { page, size } = this.fetchMixin.pagination || { page: 1, size: 20 };
      return LeaderboardDefinitionService.fetchLeaderboardDefinitions({ page, size });
    },
    addToList(leaderboardDefinition) {
      this.leaderboardDefinitions = [...this.leaderboardDefinitions, leaderboardDefinition];
    },
    removeFromList(id) {
      this.leaderboardDefinitions = this.leaderboardDefinitions.filter((leaderboardDefinition) => leaderboardDefinition.id !== id);
    },
    deleteLeaderboardDefinition(id) {
      return LeaderboardDefinitionService.deleteLeaderboardDefinition(id);
    },
  },
  created() {
    this.fetchMixin.config = {
      dataField: 'leaderboardDefinitions',
      fetch: 'fetchLeaderboardDefinitions',
    };
    this.deleteMixin.config = {
      delete: 'deleteLeaderboardDefinition',
      callback: 'removeFromList',
    };
    this.fetch();
  },
};
</script>
